.skills__container
{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap:2rem;
}

.skills__container>div
{
    background: var(--color-bg-variant);
    padding: 2.4rem;
    border-radius: 2rem;
    border:1px solid transparent;
    transition: var(--transition);
}

.skills__container>div:hover
{
    background: transparent;
    background-color: var(--color-primary-variant);
    cursor:default;

}

.skills__container>div h3
{
    text-align: center;
    margin-bottom: 2rem;
    color:var(--color-primary);
}

.skills__content
{
    display: grid;
    grid-template-columns: 1fr 1fr;
    row-gap: 2rem;
}

.skills__details
{
    display: flex;
    gap:1rem;
}

.skills__details-icon
{
    margin-top:6px;
    color:var(--color-primary);
}
@media screen and (max-width:1024){
    .skills__container
    {
        grid-template-columns: 1fr;
    }

    .skills__container>div
    {
        width:80%;
        padding:2rem;
        margin:0 auto;
    }
    .skills__content
    {
        padding: 1rem;
    }
    
} 
@media screen and (max-width:600px){
    .skills__container{
        gap:1rem;
    }
    .skills__container>div
    {
        width: 100%;
        padding:  2rem 1rem;
    }
}