.portfolio__container
{
    display: grid;
    grid-template-columns: repeat(2,1fr);
    gap:5rem;
}
.portfolio__items
{
    background-color: var(--color-bg-variant);
    padding:3rem;
    border-radius: 2rem;
    border:1px solid transparent;
    transition: var(--transition);
}
.portfolio__item
{
    background: var(--color-bg-variant);
    padding: 1.2rem;
    border-radius: 2rem;
    border: 1px solid transparent;
    transition: var(--transition);
}
.porfolio__item:hover
{
    border-color:var(--color-primary-variant);
    background: transparent;
}

.porfolio__item-image
{
    border-radius: 1.5rem;
    overflow: hidden;
}

.portfolio__item h3
{
    margin: 1.2rem;
}

.portfolio__item-cta
{
    display: flex;
    gap:1rem;
    margin-bottom:1rem;
}
@media screen and (width:600px){
    .portfolio__container{
        grid-template-columns: 1fr;
        gap:1rem;
    }
}